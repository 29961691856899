<template>
  <div>

    <home-page-add
        :is-add-new-home-page-sidebar-active.sync="isAddNewHomePage"
        @refetch-data="refetchData"
        :pages="pages"
    />

    <home-page-edit
        :pages="pages"
        :show="showModal"
        :banner="homepage"
        @close="showModal = false"
        @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.show") }}</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("labels.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('buttons.search') + '...'"
              />
              <div>
                <b-button
                    variant="primary"
                    @click="$router.push({name:'apps-homepages-store', query: { templateId: templateID }})"
                >
                  <span class="text-nowrap">{{ $t('buttons.add') }}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay :show="loading"
                 :variant="$store.state.appConfig.layout.skin"
                 blur="2"
                 class="p-50">
        <b-table
            ref="refHomePageListTable"
            class="position-relative"
            :items="fetchHomePages"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="$t('list_empty')"
            :sort-desc.sync="isSortDirDesc"
            style="min-height: 60vh"
        >

          <!-- Column: Name -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <small class="text-muted">{{ data.item.name }}</small>
            </b-media>
          </template>

          <!-- Column: Description -->
          <template #cell(description)="data">
            <b-media vertical-align="center">
              <small class="text-muted">{{ data.item.description }}</small>
            </b-media>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">


            <b-button variant="primary" class="btn-icon rounded-circle mr-1"
                      @click="selectHomePage(data.item._id)"
                      :style="isSelected(data.item._id) ? 'pointer-events: none;' : ''"
                      v-b-tooltip.hover.top="$t('labels.select')" size="sm">
              <feather-icon :icon="isSelected(data.item._id) ? 'CheckCircleIcon' : 'CircleIcon'"/>

            </b-button>

            <b-button v-if="isSelected(data.item._id)" variant="primary" class="btn-icon rounded-circle mr-1"
                      v-b-tooltip.hover.top="$t('tooltips.edit')" size="sm"
                      :to="{ name: 'apps-homepages-edit', params: { id: data.item._id }, query: { templateId: templateID } }"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button variant="primary" class="btn-icon rounded-circle mr-1"
                      v-if="!isSelected(data.item._id)"
                      v-b-tooltip.hover.top="$t('tooltips.delete')" @click="removeHomePage(data.item._id)" size="sm">
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </template>

        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
            >{{ $t("labels.showing") }} {{ dataMeta.from }}
              {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
              {{ dataMeta.of }} {{ $t("labels.entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination, VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import {ref, onUnmounted, computed, onMounted} from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ImageTableField from "@/views/components/ImageTableField.vue";
import useHomePageList from "../useHomePageList";
import HomePageEdit from "./HomePageEdit.vue";
import HomePageAdd from "./HomePageAdd.vue";
import homePageStoreModule from "@/views/components/whitelabel-templates/App/config/PageEdit/HomePageManagement/external/homePages/homePageStoreModule";
import {showErrorToast, showSuccessToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";

export default {
  name: "HomePageList",
  components: {
    HomePageEdit,
    HomePageAdd,
    ImageTableField,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      homepage: {
        default: {}
      },
      showModal: false,
      pages: [
        {
          value: 1,
          text: "Home"
        },
        {
          value: 2,
          text: "About"
        }
      ]
    };
  },

  computed: {
    tableColumns() {
      return [

        {key: "name", sortable: true, label: this.$t("labels.name")},
        {
          key: "description",
          sortable: true,
          label: this.$t("labels.description"),
        },
        {key: "actions", label: this.$t("labels.actions")},
        {key: "selection", label: ''},
      ]
    }
  },

  directives: {
    'b-tooltip': VBTooltip
  },

  setup(props, {emit}) {
    const HOMEPAGE_APP_STORE_MODULE_NAME = "app-homepage";

    if (!store.hasModule(HOMEPAGE_APP_STORE_MODULE_NAME))
      store.registerModule(HOMEPAGE_APP_STORE_MODULE_NAME, homePageStoreModule);

    const prepareUpdateOrCreate = (item = null, action = 'edit') => {
      emit('updateOrCreate', {item, action})
    }


    const isAddNewHomePage = ref(false);

    const {
      fetchHomePages,
      selectHomePage,
      isSelected,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refHomePageListTable,
      refetchData,
      recordDeleted,
      loading,
      templateID,
      resolveBannerStatusVariant,
    } = useHomePageList();

    return {
      // Sidebar
      isAddNewHomePage,
      fetchHomePages,
      selectHomePage,
      prepareUpdateOrCreate,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refHomePageListTable,
      refetchData,
      recordDeleted,
      isSelected,
      loading,
      templateID,
      // UI
      resolveBannerStatusVariant,
    };
  },
  methods: {
    async removeHomePage(id) {
      try {
        await store.dispatch("app-homepage/removeHomePage", { id });
        this.recordDeleted = !this.recordDeleted;
        showSuccessToast(this.$toast, "Home page", this.$t('homepage_removed'));
        this.refetchData();
      } catch (error) {
        showErrorToast(this.$toast, "Error", axiosErrorHandle(error));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select";
</style>
