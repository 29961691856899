<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { computed, onUnmounted, ref, watch } from "@vue/composition-api";
import store from "@/store";
import menuStoreModule from "./store/menuStoreModule";
import MenuForm from "./menuForm.vue";
import { useToast } from "vue-toastification/composition";
import axios from "@/libs/axios";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import i18n from "@/libs/i18n";
import LinkList from "@/views/components/whitelabel-templates/App/config/PageEdit/MenuManagement/linkList.vue";

const APP_URL = process.env.VUE_APP_URL;
const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "index",
  components: {
    LinkList,
    BFormInvalidFeedback,
    ImageFieldForm,
    vSelect,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    MenuForm,
    BForm,
    BRow,
    BCol,
    BFormGroup,
  },

  props: {
    options: {
      default: {
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "menu",
        visible: false,
      },
    },
  },

  setup(props, { emit }) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const MENU_APP_STORE_MODULE_NAME = "app-menu-store";
    const menu = ref(null);
    const loading = ref(false);
    const editLink = ref(false);
    const formValid = ref(false);

    const images = ref([]);
    const toast = useToast();
    const options = ref([
      { text: i18n.t("left"), value: 1 },
      { text: i18n.t("center"), value: 2 },
      { text: i18n.t("right"), value: 3 },
    ]);

    const settings = ref({
      status: "list", // 'list' | 'form'
      action: "edit", // 'edit' | 'add'
    });

    const link = ref({
      buttonType: "",
      position: "",
      title: "",
    }); // Represents the current link being edited/created

    const products = ref([]); // Array to store available products
    const lobbies = ref([]); // Array to store available lobbies
    const backURL = ref(BACK_URL); // Base URL for the backend
    const lobbySelected = ref({}); // Represents the currently selected lobby for the link
    const productSelected = ref({}); // Represents the currently selected product for the link
    const subLink = ref({ buttonType: "link", position: 0 }); // Represents the current sub-link being edited/created
    const editingSubLink = ref(false); // Flag to indicate if a sub-link is being edited

    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME)) {
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    }

    if (!store.hasModule(MENU_APP_STORE_MODULE_NAME))
      store.registerModule(MENU_APP_STORE_MODULE_NAME, menuStoreModule);

    onUnmounted(() => {
      // if (store.hasModule(MODULE_APP_STORE_NAME))
      //   store.unregisterModule(MODULE_APP_STORE_NAME);
      // if (store.hasModule(MODULE_APP_STORE_NAME_MENU))
      //   store.unregisterModule(MODULE_APP_STORE_NAME_MENU);
    });

    // Computed property to access the editTemplate state from the Vuex store
    // This will hold the template data that needs to be edited
    const editTemplate = computed(() => {
      return store.state["app-whitelabel-templates"].editTemplate;
    });

    // Fetch images from the server for the current client's menu
    const storeImage = async () => {
      axios
        .get(
          `${APP_URL}/files/${store.state.whitelabelCurrencyNabvar.whitelabel.client}?path=menu`
        )
        .then((response) => {
          images.value = response.data;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            images.value = [];
          }
        });
    };

    // Update the editLink value based on the provided id
    // This method is called when a user selects a link in the navbar
    // The id represents the position of the link in the menu structure
    const editLinkHandler = (val) => {
      const exist =
        menu.value.links.find((item) => item.id === val.id) !== undefined;
      if (exist) {
        link.value = val;
        enableEditMode();
        showForm();
      } else {
        resetLink();
        showList();
      }
    };

    const resetLink = () => {
      link.value = {
        buttonType: "",
        position: "",
        title: "",
      };
    };

    const generateUUID = () => {
      return "uuidxxxxxxxxxxxxxxxxxxxx".replace(/[x]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    };

    const showList = () => {
      settings.value.status = "list";
    };

    const showForm = () => {
      settings.value.status = "form";
    };

    const enableEditMode = () => {
      settings.value.action = "edit";
    };

    const enableAddMode = () => {
      settings.value.action = "add";
    };

    const resetAll = () => {
      resetLink();
      resetProduct();
      resetLobby();
      showList();
      store.commit("app-whitelabel-templates-menu/SET_CLICKED_LINK", null);
    };

    const linkButtonHandler = () => {
      if (!formValid.value) {
        return;
      }

      if (settings.value.action === "edit") {
        updateLink();
      } else {
        saveLink();
      }
    };

    const updateLink = () => {
      menu.value.links = menu.value.links.map((item) => {
        if (item.id === link.value.id) {
          return link.value;
        }
        return item;
      });
      showList();
      resetAll();
      sortMenuLinks();
    };

    const saveLink = () => {
      link.value = {
        id: generateUUID(),
        ...link.value,
      };
      menu.value.links.push(link.value);

      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          menu: menu.value
        }
      };

      store.commit('app-whitelabel-templates/SET_EDIT_TEMPLATE', updatedTemplate);
      
      showList();
      resetAll();
      sortMenuLinks();
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateMenu();
      } else {
        createMenu();
      }
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch("app-whitelabel-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: "templateData.menu"
        });
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.msg || i18n.t("error_updating_template"),
            icon: "EditIcon",
            variant: "error",
          },
        });
      }
    };

    const createMenu = async () => {
      loading.value = true;
      menu.value.whitelabelId =
        store.state.whitelabelCurrencyNabvar.whitelabel._id;
      menu.value.templateId = editTemplate.value._id;

      await store
        .dispatch("app-menu-store/addMenu", menu.value)
        .then(async (r) => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("menu_created"),
              icon: "EditIcon",
              variant: "success",
            },
          });

          const updatedTemplate = {
            ...editTemplate.value,
            templateData: {
              ...editTemplate.value.templateData,
              menu: r.data
            }
          };

          store.commit('app-whitelabel-templates/SET_EDIT_TEMPLATE', updatedTemplate);
          
          await updateTemplate();
          emit("created", r.data);
          loading.value = false;
        });
    };

    const updateMenu = async () => {
      loading.value = true;
      try {
        store
          .dispatch("app-menu-store/updateMenu", {
            id: menu.value._id,
            menuData: menu.value,
          })
          .then(async (r) => {
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("menu_updated"),
                icon: "EditIcon",
                variant: "success",
              },
            });

            const updatedTemplate = {
              ...editTemplate.value,
              templateData: {
                ...editTemplate.value.templateData,
                menu: r.data
              }
            };

            store.commit('app-whitelabel-templates/SET_EDIT_TEMPLATE', updatedTemplate);
            
            await updateTemplate();
            emit("updated", r.data);
            loading.value = false;
          });
      } catch (error) {
        loading.value = false;
        toast({
          component: ToastificationContent,
          props: {
            title: error.msg || this.$t("error_creating_updating_menu"),
            icon: "EditIcon",
            variant: "error",
          },
        });
      }
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.menu");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      resetAll();
      emit("reset");
    };

    const isEditMode = computed(() => {
      return props.options.action === "edit";
    });

    const resetMenu = () => {
      menu.value = {
        background_color: "",
        name: "",
        font_size: "",
        hover_color: "",
        color: "",
        links: [],
      };

      editLink.value = false;
    };

    const fetchImages = async () => {
      axios
        .get(
          `${APP_URL}/files/${store.state.whitelabelCurrencyNabvar.whitelabel.client}?path=menu`
        )
        .then((response) => {
          images.value = response.data;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            images.value = [];
          }
        });
    };

    const setOrReloadImages = (value) => {
      fetchImages();
      menu.value.background_image = value;
    };

    const resetProduct = () => {
      productSelected.value = { position: 0 };
    };

    const resetLobby = () => {
      lobbySelected.value = { position: 0 };
    };

    const product = computed({
      get: () => {
        return productSelected.value;
      },
      set: (val) => {
        // If the value has a 'position' property, update the link position
        if (val.hasOwnProperty("position")) {
          link.value.position = val.position;
        } else {
          // Otherwise, update the selected product and link details
          productSelected.value = val;
          link.value.id = val.productId;
          link.value.title = val.name;
          link.value.href = `/product/${link.value.id}`;
        }
      },
    });

    const lobby = computed({
      get: () => {
        return lobbySelected.value;
      },
      set: (val) => {
        lobbySelected.value = val;
        if (val.hasOwnProperty("position")) {
          link.value.position = val.position;
        } else {
          // Otherwise, update the selected lobby and link details
          link.value.id = val._id;
          link.value.title = val.name;
          link.value.href = `/lobby?id=${val._id}`;
          link.value.position = lobby.value.position || 0;
        }
      },
    });

    const getLobbies = async () => {
      //lobbies get
      const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
      try {
        const response = await axios.get(backURL.value + "/lobbies", {
          params: {
            whitelabelId,
            templateId: editTemplate.value._id
          }
        });
        lobbies.value = response.data.lobbys;
      } catch (err) {
        console.error(err);
      }
    };

    const getProducts = async () => {
      //products get
      try {
        const clientId = store.state.whitelabelCurrencyNabvar.whitelabel.client;
        const response = await axios.get(
          `${backURL.value}/clients/availProd/${clientId}`
        );
        const objects = response.data;
        objects.forEach((element) => {
          products.value.push({
            productId: element.product.productId,
            name: element.product.name,
          });
        });
        // this.products = data.filter((pro) => pro.isgame);
      } catch (err) {
        console.error(err);
      }
    };

    const onEditLink = (value) => {
      showForm();
      enableEditMode();
      link.value = value;
    };

    const onAddLink = () => {
      showForm();
      enableAddMode();
      resetLink();
    };

    const onDeleteLink = (id) => {
      menu.value.links = menu.value.links.filter((item) => item.id !== id);
    };

    const sortMenuLinks = () => {
      const links = menu.value.links;
      links.sort((a, b) => Number(a.position) - Number(b.position));
      menu.value.links = links;
    };

    const checkForm = () => {
      const required = ["title", "position"];
      formValid.value = true;
      required.forEach((key) => {
        if (!link.value[key]) {
          formValid.value = false;
        }
      });
    };

    watch(
      () => props.options,
      (value) => {
        if (value.action === "edit") {
          menu.value = value.itemsSelected;
        } else {
          resetMenu();
        }
      },
      { immediate: true }
    );

    watch(
      () => link.value,
      () => {
        checkForm();
      },
      { deep: true }
    );

    watch(
      () => menu.value,
      (value) => {
        const template = editTemplate.value;
        checkForm();
        const updatedTemplate = {
          ...editTemplate.value,
          templateData: {
            ...editTemplate.value.templateData,
            menu: value
          }
        };

        store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
      },
      { deep: true, immediate: true }
    );

    watch(
      () => store.state.whitelabelCurrencyNabvar.whitelabel,
      () => {
        storeImage();
      }
    );

    //Watch for changes in the 'link' state
    //When a user selects a link in the navbar the position of the link is saved
    //Knowing the position we can edit or delete that link
    watch(
      () => store.state["app-whitelabel-templates-menu"].link,
      (val) => {
        if (val) {
          editLinkHandler(val);
        }
      }
    );

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      () => {}
    );

    return {
      editTemplate,
      setOrReloadImages,
      editLinkHandler,
      images,
      lobby,
      settings,
      getLobbies,
      storeImage,
      linkButtonHandler,
      onSubmit,
      resetMenu,
      fetchImages,
      refFormObserver,
      getValidationState,
      resetForm,
      isEditMode,
      resetData,
      getProducts,
      product,
      lobbies,
      products,
      loading,
      link,
      menu,
      resetLink,
      resetAll,
      onEditLink,
      onDeleteLink,
      onAddLink,
      formValid,
    };
  },

  mounted() {
    this.storeImage();
  },

  created() {
    this.getLobbies();
    this.getProducts();
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-row>
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input
                    id="name"
                    type="text"
                    v-model="menu.name"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
              #default="validationContext"
              name="fontSize"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="With" label-for="fontSize">
                  <template #label>{{ $t("font_size") }}</template>
                  <b-form-input
                    id="fontSize"
                    type="number"
                    v-model="menu.font_size"
                    :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
              #default="validationContext"
              name="BackgroundColor"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group
                  label="Background color"
                  label-for="backgroundcolor"
                >
                  <template #label>{{ $t("labels.backgroundcolor") }}</template>
                  <b-form-input
                    id="backgroundcolor"
                    type="color"
                    v-model="menu.background_color"
                    :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
              #default="validationContext"
              name="textColor"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="text color" label-for="textColor">
                  <template #label>{{ $t("text_color") }}</template>
                  <b-form-input
                    id="textColor"
                    type="color"
                    v-model="menu.color"
                    :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
              #default="validationContext"
              name="hoverColor"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="text color" label-for="hoverColor">
                  <template #label>{{ $t("labels.hovercolor") }}</template>
                  <b-form-input
                    id="hoverColor"
                    type="color"
                    v-model="menu.hover_color"
                    :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <image-field-form
                :preview="true"
                id="backgroundImage"
                :text="$t('labels.backgroundimage')"
                v-model="menu.background_image"
                @changeImage="setOrReloadImages"
                path="menu"
                :images="images"
              >
              </image-field-form>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Settings">
          <div class="accordion" role="tablist">
            <b-list-group class="list-group-flush mt-2">
              <b-list-group-item style="background: none" class="px-0">
                <h6 v-b-toggle.accordion-1>{{ $t("labels.links") }}</h6>

                <b-collapse
                  visible
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <link-list
                    v-if="settings.status === 'list'"
                    @add="onAddLink()"
                    @edit="onEditLink($event)"
                    @delete="onDeleteLink($event)"
                    :links="menu.links"
                  >
                  </link-list>

                  <div class="mt-1" v-if="settings.status === 'form'">
                    <div class="d-flex align-items-center">
                      <b-col v-if="!link.id" class="border-md-left" cols="12">
                        <validation-provider
                          #default="validationContext"
                          name="type"
                        >
                          <b-form-group label-for="type">
                            <template #label>{{ $t("button_type") }}</template>
                            <v-select
                              v-model="link.buttonType"
                              label="text"
                              :options="['link', 'submenu', 'product', 'lobby']"
                              :state="getValidationState(validationContext)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                      <template
                        v-if="
                          link.buttonType === 'link' ||
                          (link.buttonType === 'product' && !!link.id) ||
                          (link.buttonType === 'lobby' && !!link.id)
                        "
                      >
                        <b-col class="border-md-left" cols="12">
                          <validation-provider
                            rules="required"
                            #default="validationContext"
                            name="title"
                          >
                            <b-form-group label-for="title">
                              <template #label>{{ $t("title") }}</template>
                              <b-form-input
                                id="title"
                                required
                                type="text"
                                v-model="link.title"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col class="border-md-left" cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="url"
                          >
                            <b-form-group label-for="url">
                              <template #label>url</template>
                              <b-form-input
                                :disabled="
                                  link.buttonType === 'product' ||
                                  link.buttonType === 'lobby'
                                "
                                id="url"
                                type="text"
                                v-model="link.href"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>

                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="icon"
                          >
                            <b-form-group label-for="icon">
                              <template #label>icon</template>
                              <b-form-input
                                id="icon"
                                type="text"
                                v-model="link.icon"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>

                        <b-col cols="12">
                          <validation-provider
                            rules="required"
                            #default="validationContext"
                            name="position"
                          >
                            <b-form-group label-for="position">
                              <template #label>position</template>
                              <b-form-input
                                id="position"
                                type="number"
                                min="0"
                                required
                                v-model="link.position"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </template>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                      <template v-if="link.buttonType === 'submenu'">
                        <b-col cols="12">
                          <validation-provider
                            rules="required"
                            #default="validationContext"
                            name="title"
                          >
                            <b-form-group label-for="title">
                              <template #label>{{ $t("title") }}</template>
                              <b-form-input
                                id="title"
                                required
                                type="text"
                                v-model="link.title"
                                :state="getValidationState(validationContext)"
                              />

                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            :name="$t('icon')"
                          >
                            <b-form-group label-for="icon">
                              <template #label>{{ $t("icon") }}</template>
                              <b-form-input
                                id="icon"
                                type="text"
                                v-model="link.icon"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <validation-provider
                            rules="required"
                            #default="validationContext"
                            name="position"
                          >
                            <b-form-group label-for="position">
                              <template #label>{{ $t("position") }}</template>
                              <b-form-input
                                required
                                id="position"
                                type="number"
                                min="0"
                                v-model="link.position"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </template>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                      <template
                        v-if="link.buttonType === 'product' && !link.id"
                      >
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="Products"
                            rules="required"
                          >
                            <b-form-group label-for="products">
                              <template #label
                                >{{ $t("labels.products") }}
                              </template>
                              <v-select
                                v-model="product"
                                required
                                label="name"
                                :options="products"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            rules="required"
                            name="position"
                          >
                            <b-form-group label-for="position">
                              <template #label>position</template>
                              <b-form-input
                                required
                                id="position"
                                type="number"
                                min="0"
                                v-model="product.position"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </template>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                      <template v-if="link.buttonType === 'lobby' && !link.id">
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="lobby"
                            rules="required"
                          >
                            <b-form-group label-for="lobby">
                              <template #label>{{ $t("lobby") }}</template>
                              <v-select
                                rulses="required"
                                v-model="lobby"
                                label="name"
                                track-by="name"
                                :options="lobbies"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <validation-provider
                            rules="required"
                            #default="validationContext"
                            name="position"
                          >
                            <b-form-group label-for="position">
                              <template #label>position</template>
                              <b-form-input
                                id="position"
                                type="number"
                                required
                                min="0"
                                v-model="lobby.position"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </template>
                    </div>
                    <div class="row px-1" v-if="link.buttonType">
                      <div class="col">
                        <b-button
                          :disabled="!formValid"
                          class="w-100 mt-1"
                          @click="linkButtonHandler"
                          variant="primary"
                        >
                          {{
                            settings.action === "edit"
                              ? $t("buttons.edit")
                              : $t("buttons.add")
                          }}
                        </b-button>
                      </div>
                      <div class="col">
                        <b-button
                          class="w-100 mt-1"
                          variant="secondary"
                          @click="resetAll()"
                        >
                          {{ $t("buttons.cancel") }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
            :disabled="loading"
            size="md"
            type="submit"
            class="w-100"
            variant="success"
          >
            {{
              isEditMode
                ? $t("buttons.update_and_apply")
                : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()">{{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss"></style>
