<script>
import {BButton, BFormInput} from "bootstrap-vue";
import {computed, onBeforeUnmount, onMounted, ref, warn, watch} from "@vue/composition-api";
import store from "@/store";
import {useToast} from "vue-toastification/composition";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import appStoreModule from "@/views/components/whitelabel-templates/App/store/appStoreModule";
import {showErrorToast, showSuccessToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import i18n from "@/libs/i18n";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  name: "GEditColors",
  components: {BButton, BFormInput},

  setup() {
    const MODULE_TEMPLATE_STORE_NAME = "whitelabel-templates-store";
    const MODULE_APP_STORE_NAME = "app-store";

    // Register module
    if (!store.hasModule(MODULE_TEMPLATE_STORE_NAME))
      store.registerModule(MODULE_TEMPLATE_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, appStoreModule);

    const toast = useToast();
    const debounceTimer = ref(null);

    const colors = ref({
      userProfile: {
        bgPrimary: "",
        bgSecondary: "",
        colorBorder: "",
        colorPrimary: "",
        colorPrimaryText: "",
        colorSecondary: "",
      },
    });

    const loading = ref(false)

    const updatePreview = () => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          user_profile: {
            ...editTemplate.value.templateData.user_profile,
            colors: colors.value.userProfile
          }
        }
      };

      store.commit(`${MODULE_TEMPLATE_STORE_NAME}/SET_EDIT_TEMPLATE`, updatedTemplate);
    };

    const changePreview = (value) => {
      store.commit("app-store/SWITCH_PREVIEW", value);
    };

    onBeforeUnmount(() => {
      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }
      changePreview("general");
    });

    const editTemplate = computed(() => {
      return store.state["app-whitelabel-templates"].editTemplate;
    });

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE","templateData.user_profile");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      updateLocalColors()
    };

    const updateLocalColors = () => {
      try {
        colors.value.userProfile = editTemplate.value.templateData.user_profile.colors;
      } catch (e) {
        console.warn('APP TEMPLATE COLORS NOT EXIST OR INCORRECT FORMAT 🙈.');
      }
    };

    onMounted(() => {
      changePreview('profile')
      updateLocalColors();
    });


    const onColorChange = ({key, value, section}) => {
      console.log(editTemplate.value.templateData)
      colors.value[section][key] = value;

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 1000); // 5000 ms = 5 segundos
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
        () => {}
    );


    const updateTemplate = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            `${MODULE_TEMPLATE_STORE_NAME}/updateTemplate`,
            {
              template: editTemplate.value,
              toast,
              path: "templateData.user_profile"
            }
        );

        showSuccessToast(toast, "Colors", i18n.t("colors_updated"));
        updatePreview(response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_colors"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };


    return {
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      colors,
      loading,
      changePreview,
      onColorChange,
    }
  },

};
</script>

<template>
  <div>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('user_profile')">
          <div class="list-group list-group-flush">
            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Background Primary</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.userProfile.bgPrimary"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'bgPrimary',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Background Secondary</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.userProfile.bgSecondary"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'bgSecondary',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Border Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.userProfile.colorBorder"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'colorBorder',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Primary Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.userProfile.colorPrimary"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'colorPrimary',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Text. Primary Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.userProfile.colorPrimaryText"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'colorPrimaryText',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>

            <a
                class="list-group-item border-0 list-group-item-action d-flex gap-3"
                aria-current="true"
            >
              <div class="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 class="mt-1">Secondary Color</h6>
                </div>
                <span class="color-box">
                  <b-form-input
                      type="color"
                      :value="colors.userProfile.colorSecondary"
                      @input="
                      (newValue) =>
                        onColorChange({
                          key: 'colorSecondary',
                          value: newValue,
                          section: 'userProfile',
                        })
                    "
                  />
                </span>
              </div>
            </a>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <div class="row justify-content-end pt-4 pb-2">
      <div class="col-md-6">
        <b-button
            :disabled="loading"
            size="sm"
            class="w-100"
            variant="outline-primary"
            @click="resetData()"
        >
          {{ $t("buttons.cancel") }}
        </b-button>
      </div>

      <div class="col-md-6">
        <b-button
            :disabled="loading"
            class="w-100"
            @click="updateTemplate"
            size="sm"
            variant="primary"
        >
          Guardar</b-button
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list-group {
  max-height: 300px;
  overflow-y: scroll;
  width: 300px;
}

.color-box {
  width: 50px;
}
</style>
