<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("labels.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <div>
                <b-button
                  variant="primary"
                  @click="prepareUpdateOrCreate(null, 'create')"
                >
                  <span class="text-nowrap">{{ $t("buttons.add") }}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay
        :show="loading"
        :variant="$store.state.appConfig.layout.skin"
        blur="2"
        class="p-50"
      >
        <b-table
          ref="refMenuListTable"
          class="position-relative"
          :items="fetchMenus"
          responsive
          :fields="tableColumns"
          primary-key="_id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="this.$t('messages.nomatching')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(background)="data">
            <color-table-field :color="data.item.background_color">
              <image-table-field
                :url="data.item.background_image"
              ></image-table-field>
            </color-table-field>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              variant="primary"
              class="btn-icon rounded-circle mr-1"
              @click="selectMenu(data.item._id)"
              :style="isSelected(data.item._id) ? 'pointer-events: none;' : ''"
              v-b-tooltip.hover.top="$t('labels.select')"
              size="sm"
            >
              <feather-icon
                :icon="
                  isSelected(data.item._id) ? 'CheckCircleIcon' : 'CircleIcon'
                "
              />
            </b-button>

            <b-button
              variant="primary"
              class="btn-icon rounded-circle mr-1"
              v-if="isSelected(data.item._id)"
              v-b-tooltip.hover.top="$t('tooltips.edit')"
              size="sm"
              @click="prepareUpdateOrCreate(data.item, 'edit')"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              variant="primary"
              class="btn-icon rounded-circle mr-1"
              v-if="!isSelected(data.item._id)"
              v-b-tooltip.hover.top="$t('tooltips.delete')"
              @click="removeMenu(data.item._id)"
              size="sm"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("labels.showing") }} {{ dataMeta.from }}
              {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
              {{ dataMeta.of }} {{ $t("labels.entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalMenus"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import store from "@/store";
import vSelect from "vue-select";
import ColorTableField from "@/views/components/ColorTableField.vue";
import ImageTableField from "@/views/components/ImageTableField.vue";
import { onUnmounted, ref } from "@vue/composition-api";
import menuStoreModule from "./store/menuStoreModule";
import useMenuList from "./useMenuList";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { useToast } from "vue-toastification/composition";

export default {
  name: "menuList",
  components: {
    BButton,
    ImageTableField,
    BCard,
    BTable,
    BCol,
    BRow,
    BPagination,
    BFormInput,
    ColorTableField,
    vSelect,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
  },

  computed: {
    tableColumns() {
      return [
        { key: "name", sortable: false, label: this.$t("labels.name") },
        {
          key: "background",
          sortable: false,
          label: this.$t("labels.background"),
        },
        { key: "actions", label: this.$t("labels.actions") },
      ];
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  setup(props, { emit }) {
    const MENU_APP_STORE_MODULE_NAME = "app-menu";

    if (!store.hasModule(MENU_APP_STORE_MODULE_NAME))
      store.registerModule(MENU_APP_STORE_MODULE_NAME, menuStoreModule);

    const isAddNewMenuSidebarActive = ref(false);
    const isAddLinkMenuSidebarActive = ref(false);

    const prepareUpdateOrCreate = (item = null, action = "edit") => {
      emit("updateOrCreate", { item, action });
    };

    const {
      fetchMenus,
      perPage,
      currentPage,
      totalMenus,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMenuListTable,
      refetchData,
      selectMenu,
      isSelected,
      recordDeleted,
      loading,
      resolveMenuStatusVariant,
    } = useMenuList();

    return {
      isAddNewMenuSidebarActive,
      isAddLinkMenuSidebarActive,
      fetchMenus,
      perPage,
      currentPage,
      totalMenus,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMenuListTable,
      refetchData,
      isSelected,
      recordDeleted,
      loading,
      selectMenu,
      prepareUpdateOrCreate,
      resolveMenuStatusVariant,
    };
  },

  methods: {
    async removeMenu(Id) {
      try {
        await store.dispatch("app-menu/removeMenu", { id: Id });
        this.recordDeleted = !this.recordDeleted;
        showSuccessToast(this.$toast, "Menu", "Menu removed");
      } catch (error) {
        showErrorToast(this.$toast, "Error", axiosErrorHandle(error));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
