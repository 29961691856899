<script>
import {onMounted} from "@vue/composition-api";

export default {
  name: "LinkList",
  props: {
    links: {
      default: []
    }
  },
  components: {
  },

  setup(props, {emit}) {

    const onEdit = (item) => {
      emit('edit', item)
    }

    const onAdd = () => {
      emit('add')
    }

    const onDelete = (id) => {
      emit('delete', id)
    }


    return {
      onEdit,
      onAdd,
      onDelete
    }

  },



}

</script>

<template>
  <div>

  <b-list-group flush v-if="links.length > 0">
    <b-list-group-item class="d-inline flex" v-for="(link, index) in links" :key="index">
      <div class="d-inline"><strong class="text-caption">{{ link.title }}</strong> <small>({{
          link.buttonType
        }})</small></div>
      <div class="float-right">
        <b-button-group>
          <b-button @click="onEdit(link)" variant="outline-secondary" class="border-0">
            <feather-icon icon="EditIcon"/>
          </b-button>
          <b-button @click="onDelete(link.id)" variant="outline-secondary" class="border-0">
            <feather-icon icon="TrashIcon"/>
          </b-button>
        </b-button-group>
      </div>
    </b-list-group-item>
  </b-list-group>
    <div class="d-flex mt-2 justify-content-end">
      <b-button variant="outline-primary"  @click="onAdd()">{{$t('add_link')}}</b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">

.list-group-item {
  background: transparent !important;
  padding-inline: 0 !important;
  font-size: 12px;
}

.list-group-item p {
  margin-bottom: 0;
}

.btn-outline-secondary {
  padding: 5px 8px;
  border: none !important;
}

</style>
