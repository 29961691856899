<script>
import {BButton, BForm, BFormInput} from "bootstrap-vue";
import {ref} from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import {ValidationObserver} from "vee-validate";

export default {
  name: "index",
  components: { BButton, BForm, BFormInput, ValidationObserver},

  setup() {

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );


    const homePage = ref({})

    const onSubmit = () => {

    }

    const resetData = () => []

    return {
      onSubmit,
      resetForm,
      formValidation,
      resetData,
      getValidationState,
      refFormObserver,
      homePage
    }
  }
}
</script>

<template>
  <div>
    index works!
  </div>
</template>

<style scoped lang="scss">

</style>
