
<template>
  <b-sidebar id="add-new-Lobby-sidebar" :visible="isAddNewLobbySidebarActive" bg-variant="white"
             sidebar-class="sidebar-lg" shadow backdrop no-Lobby right @hidden="resetForm"
             @change="(val) => $emit('update:is-add-new-lobby-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Lobby -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-Lobby px-2 py-1">
        <h5 class="mb-0">
          {{ $t('tabs.addnewlobby') }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="onSubmit" @reset.prevent="resetForm">

          <!-- Name -->
          <div class="d-flex justify-content-between">
            <validation-provider #default="validationContext" rules="required">
              <b-form-group label-for="name">
                <h5>
                  {{ $t('labels.name') }}
                </h5>
                <b-form-input id="name" v-model="lobbyData.name" :state="getValidationState(validationContext)" trim />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Background Color -->
            <validation-provider #default="validationContext" name="backgroundcolor" rules="required">
              <b-form-group label-for="backgroundcolor">
                <h5>
                  {{ $t('labels.backgroundcolor') }}
                </h5>
                <b-form-input type="color" id="backgroundcolor" v-model="lobbyData.background_color"
                              :state="getValidationState(validationContext)" trim />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>


          <!-- BackgroundImage -->
          <div class="d-flex justify-content-between align-items-center">
            <validation-provider #default="validationContext" name="BackgroundImage" rules="required|url"
                                 style="width:65%">
              <b-form-group label-for="backgrounimage">
                <h5>
                  {{ $t('labels.backgroundimage') }}
                </h5>
                <b-form-file id="backgroundimage" v-model="lobbyData.background_image"
                             :state="Boolean(lobbyData.background_image) || getValidationState(validationContext)"
                             placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- TypePage -->
            <validation-provider #default="validationContext" name="pageType" rules="required">
              <b-form-group label-for="typePage">
                <h5>
                  {{ $t('labels.pageType') }}
                </h5>
                <b-form-select id="typePage" :options="optionsTypePage" v-model="lobbyData.page_type"
                               :state="getValidationState(validationContext)" trim />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </div>

          <div class="d-flex justify-content-between align-items-center">
            <!-- TypeFilter -->

            <validation-provider #default="validationContext" name="filterType" rules="required">
              <b-form-group label-for="filterType">
                <h5>
                  {{ $t('labels.filterType') }}
                </h5>
                <multiselect class="multiselect" id="filterType" v-model="lobbyData.type_filter"
                             :options="optionsFilterType" :close-on-select="false" :multiple="true" group-values="values"
                             group-label="todo" :group-select="true" :placeholder="$t('search_filter')" track-by="text" label="text">
                  <span slot="noResult">{{
                      $t('no_result')
                    }}</span>
                </multiselect>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <h5>{{ $t('labels.resume') }}</h5>

          <div class="d-flex justify-content-between">

            <!-- Resume:Font size -->
            <validation-provider #default="validationContext" rules="required">
              <b-form-group label-for="fontsize">
                <h5>
                  {{ $t('labels.fontsize') }}
                </h5>
                <b-form-input type="number" max="200" id="resumefontsize" v-model="lobbyData.resume.font_size"
                              :state="validationSizeResume" trim />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Resume:color -->
            <validation-provider #default="validationContext" name="color" rules="required" style="width:30%">
              <b-form-group label="color" label-for="color">
                <template #label>{{ $t('labels.color') }}</template>
                <b-form-input type="color" id="backgroundcolor" v-model="lobbyData.resume.color"
                              :state="getValidationState(validationContext)" trim />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <!-- Resume:Text -->
          <validation-provider #default="validationContext" rules="required">
            <b-form-group label="Text" label-for="text">
              <template #label>{{ $t('labels.text') }}</template>
              <b-form-input id="text" v-model="lobbyData.resume.text" :state="getValidationState(validationContext)"
                            trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Banner image -->
          <validation-provider #default="validationContext" name="BannerImage" rules="required|url">
            <b-form-group label-for="bannerimage">
              <h5>
                {{ $t('labels.bannerimage') }}
              </h5>
              <b-form-file id="bannerimage" v-model="lobbyData.banner_image"
                           :state="Boolean(lobbyData.bannerimage) || getValidationState(validationContext)"
                           placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <h5>
            {{ $t('labels.title') }}
          </h5>

          <!-- Title:Text -->
          <validation-provider #default="validationContext" rules="required">
            <b-form-group label="Title" label-for="title">
              <template #label>{{ $t('labels.text') }}</template>
              <b-form-input id="title" v-model="lobbyData.title.text" :state="getValidationState(validationContext)"
                            trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Title:Font size -->
          <validation-provider #default="validationContext" rules="required">
            <b-form-group label-for="fontsize">
              <h5>
                {{ $t('labels.fontsize') }}
              </h5>
              <b-form-input type="number" max="200" id="resumefontsize" v-model="lobbyData.title.font_size"
                            :state="validationSizeTitle" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-between">
            <!-- Title:Color -->
            <validation-provider #default="validationContext" name="color" rules="required" style="width:30%">
              <b-form-group label="color" label-for="color">
                <template #label>{{ $t('labels.color') }}</template>
                <b-form-input type="color" id="backgroundcolor" v-model="lobbyData.title.color"
                              :state="getValidationState(validationContext)" trim />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Title: Hover color -->
            <validation-provider #default="validationContext" name="hovercolor" rules="required" style="width:30%">
              <b-form-group label="Hover color" label-for="hovercolor">
                <template #label>{{ $t('labels.hovercolor') }}</template>
                <b-form-input type="color" id="backgroundcolor" v-model="lobbyData.title.hover_color"
                              :state="getValidationState(validationContext)" trim />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>


          <h5>
            {{ $t('labels.cardType') }}
          </h5>

          <!-- Feature card: background color -->
          <validation-provider #default="validationContext" name="BackgroundColor" rules="required">
            <b-form-group label="Background color" label-for="backgroundcolor">
              <template #label>{{ $t('labels.backgroundcolor') }}</template>
              <b-form-input type="color" id="backgroundcolor" v-model="lobbyData.feature_card.background_color"
                            :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-between">
            <!-- Feature card: Card size -->
            <validation-provider #default="validationContext" name="cardsize" rules="required">
              <b-form-group label-for="cardsize">
                <template #label>{{ $t('labels.cardsize') }}</template>
                <b-form-select id="cardsize" :options="optionsFontSize" v-model="lobbyData.feature_card.card_size"
                               :state="getValidationState(validationContext)" trim />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Feature card: Card height -->
            <validation-provider #default="validationContext" name="cardheight" rules="required">
              <b-form-group label-for="cardheight">
                <template #label>{{ $t('labels.cardheight') }}</template>
                <b-form-select id="cardheight" :options="optionsFontSize" v-model="lobbyData.feature_card.card_height"
                               :state="getValidationState(validationContext)" trim />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>


          <!-- Feature card: border color -->
          <validation-provider #default="validationContext" name="BorderColor" rules="required">
            <b-form-group label="Border color" label-for="bordercolor">
              <template #label>{{ $t('labels.bordercolor') }}</template>
              <b-form-input type="color" id="bordercolor" v-model="lobbyData.feature_card.border_color"
                            :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Feature card: Border size -->
          <validation-provider #default="validationContext" name="bordersize" rules="required">
            <b-form-group label-for="bordersize">
              <template #label>{{ $t('labels.bordersize') }}</template>
              <b-form-select id="bordersize" :options="optionsBorderSize" v-model="lobbyData.feature_card.border_size"
                             :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Feature card: hover color -->
          <validation-provider #default="validationContext" name="Hovercolor" rules="required">
            <b-form-group label="hovercolor" label-for="hovercolor">
              <template #label>{{ $t('labels.hovercolor') }}</template>
              <b-form-input type="color" id="color" v-model="lobbyData.feature_card.hover_color"
                            :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Feature card: Style -->
          <validation-provider #default="validationContext" rules="required">
            <b-form-group label="Style" label-for="style">
              <template #label>{{ $t('labels.style') }}</template>
              <b-form-select id="style" :options="featureCard" v-model="lobbyData.feature_card.style"
                             :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex justify-content-between align-items-center">
            <!-- Products -->

            <validation-provider #default="validationContext" name="products" rules="required">
              <b-form-group label-for="filterType">
                <h5>
                  {{ $t('labels.products') }}
                </h5>
                <multiselect class="multiselect" id="products" v-model="lobbyData.products" :options="fetchProducts"
                             :close-on-select="false" :multiple="true" group-values="values" group-label="todo"
                             :group-select="true" placeholder="Filtro de busqueda" track-by="text" label="text">
                  <span slot="noResult">¡Ups! No se encontraron elementos. Considere cambiar la consulta de
                    búsqueda.</span>
                </multiselect>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <div class="d-flex">
            <!-- is Desplegable -->
            <b-form-group>
              <b-form-checkbox name="check-button" v-model="lobbyData.is_desplegable" switch inline>
                {{ $t('labels.dropdown') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- status -->
            <b-form-group>
              <b-form-checkbox name="check-button" v-model="lobbyData.status" switch inline>
                {{ $t('labels.status') }}
              </b-form-checkbox>
            </b-form-group>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ $t('buttons.add') }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                      @click="hide">
              {{ $t('buttons.cancel') }}
            </b-button>
          </div>

          <div>
            <br>
            <br>
            <span>*** This addition will create an empty props records. Go to Edit to update the props</span>
          </div>

        </b-form>
      </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    vSelect,
    Multiselect,
    BFormFile,
    BFormCheckbox,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  model: {
    prop: 'isAddNewLobbySidebarActive',
    event: 'update:is-add-new-Lobby-sidebar-active',
  },

  props: {
    isAddNewLobbySidebarActive: {
      type: Boolean,
      required: true,
    },

  },

  data() {
    return {
      required,
      alphaNum,
      optionsHide: [
        { value: true, text: 'True' },
        { value: false, text: 'False' }
      ],

      optionsFilterType: [{
        todo: 'Seleccionar Todos',
        values: [
          { value: 'Producto', text: this.$t('labels.products') },
          { value: 'Categoría', text: this.$t('category') },
          { value: 'Tag', text: 'Tag' },
        ]
      }],
      optionsTypePage: [
        { value: 'Slots', text: 'Slots' },
        { value: 'Casino', text: 'Casino' },
        { value: 'Mixto', text: 'Mixto' },
      ],
      featureCard: [
        { value: 'Columnas Amplias', text: 'Columnas Amplias' },
        { value: 'Comprimido', text: 'Comprimido' },
        { value: 'Espacioso', text: 'Espacioso' },
        { value: 'Amplio', text: 'Amplio' }
      ],
      optionsFontSize: [],
      optionsBorderSize: [],
      fetchProducts: [{
        todo: 'Seleccionar Todos',
        values: [],
      }],
      fetchCategories: [{
        todo: 'Seleccionar Todos',
        values: [],
      }],
    }
  },
  computed: {
    validationSizeResume() {
      return this.lobbyData.resume.font_size <= 200
    },
    validationSizeTitle() {
      return this.lobbyData.title.font_size <= 200
    }
  },
  mounted() {
    store.dispatch('app-lobby/fetchCategories')
        .then(response => {
          response.data.forEach(element => {
            this.fetchCategories[0].values.push({ value: element._id, text: element.name })
          });
        })

    store.dispatch('app-lobby/fetchProducts')
        .then(response => {
          response.data.forEach(element => {
            this.fetchProducts[0].values.push({ value: element._id, text: element.name })
          });
        })

    for (let i = 5; i < 21; i++) {
      this.optionsFontSize.push({ value: i, text: i + 'px' })
    }
    for (let i = 1; i < 6; i++) {
      this.optionsBorderSize.push({ value: i, text: i + 'px' })
    }
  },
  setup(props, { emit }) {

    const blanklobbyData =
        {
          name: "",
          background_color: "",
          background_image: "",
          type_filter: [],
          page_type: "",
          resume: {
            color: "",
            font_size: 0,
            text: "",
          },
          banner_image: "",
          title: {
            text: "",
            font_size: 0,
            color: "",
            hover_color: "",
          },
          feature_card: {
            background_color: "",
            card_size: 0,
            card_height: 0,
            border_color: "",
            border_size: 0,
            hover_color: "",
            style: "",
          },
          products: [],
          is_desplegable: false,
          status: false,
        }

    const lobbyData = ref(JSON.parse(JSON.stringify(blanklobbyData)))
    const resetlobbyData = () => {
      lobbyData.value = blanklobbyData
    }

    const onSubmit = async () => {
      try {
        await store.dispatch('app-lobby/addLobby', lobbyData.value)
        emit('refetch-data')
        emit('update:is-add-new-Lobby-sidebar-active', false)
      } catch (error) {
        emit('update:is-add-new-Lobby-sidebar-active', false)
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetlobbyData)

    return {
      lobbyData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-Lobby-sidebar {
  .vs__dropdown-Lobby {
    max-height: 200px !important;
  }
}

.multiselect {
  min-height: 100%;
  display: block;
}
</style>
<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
