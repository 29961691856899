<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import {
  computed,
  getCurrentInstance,
  onUnmounted,
  ref, watch,
} from "@vue/composition-api";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import axios from "@/libs/axios";
import _ from "lodash";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import { translatableText } from "@core/utils/utils";
import { axiosErrorHandle } from "@core/utils/errorHandler";
const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "menuForm",
  components: {
    ImageFieldForm,
    vSelect,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },
  props: ["images", "editLink", "options"],

  setup(props, {emit}) {
    onUnmounted(() => {
      resetData();
    });

    const menu = ref({
      background_color: "#000",
      name: ""
    })

    const toast = useToast();
    const vm = getCurrentInstance();

    //editing template
    const editTemplate = computed(() => {
      return store.state["app-whitelabel-templates"].editTemplate;
    });


    const onSubmit = () => {
      if(isEditMode.value) {
        updateMenu()
      } else {
        createMenu()
      }

      updateTemplate()
      resetMenu()
    }

    const updateMenu = async () => {

      try {
        await this.$store.dispatch('app-menu/updateMenu', {
          id: menu.value._id,
          menuData: menu.value
        })

        toast({
          component: ToastificationContent,
          props: {
            title: "Menu update",
            icon: "EditIcon",
            variant: "success",
          },
        });

        emit('updated', editTemplate.value.templateData.menu)
      } catch (error) {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: error.msg || "error creating or update header",
            icon: "EditIcon",
            variant: "error",
          },
        });
      }
    }

    const createMenu = async () => {
      await store.dispatch('app-menu/addMenu', menu.value)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Menu created',
          icon: 'EditIcon',
          variant: 'success',
        },
      })

      emit('created', menu.value)
    }

    const updateTemplate = async () => {

      try {
         await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              templateData: editTemplate.value,
              toast,
              path: "templateData.menu"
            }
        );
       
      } catch (error) {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: error.msg || "error updating template",
            icon: "EditIcon",
            variant: "error",
          },
        });
      }
    }

    const resetMenu = () => {
      menu.value = {
        background_image: '',
        background_color: '#000',
      }

    }

    const isEditMode = computed(() => {
      return props.options.action === 'edit'
    });

    watch(() => props.options, (value) => {
      if (value.action === 'edit') {
        menu.value = value.itemsSelected
      } else {
        resetMenu()
      }
    })

    watch(() => menu, (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          menu: value
        }
      };

      store.commit('app-whitelabel-templates/SET_EDIT_TEMPLATE', updatedTemplate);
    })


    //if the data in the backup template does not match with the current editing template (is because, the user made some change),
    //puts the backup template in the editing template (the unmodified template)
    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.menu");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");

      emit('reset')
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      () => {}
    );

    return {
      onSubmit,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      isEditMode,
      menu
    };
  },

  data() {
    return {
      // Options for the link position (left, center, right)
      options: [
        { text: this.$t("left"), value: 1 },
        { text: this.$t("center"), value: 2 },
        { text: this.$t("right"), value: 3 },
      ],
      link: { position: 0 }, // Represents the current link being edited/created
      products: [], // Array to store available products
      lobbies: [], // Array to store available lobbies
      backURL: BACK_URL, // Base URL for the backend
      lobbySelected: {}, // Represents the currently selected lobby for the link
      productSelected: {}, // Represents the currently selected product for the link
      subLink: { buttonType: "link", position: 0 }, // Represents the current sub-link being edited/created
      editingSubLink: false, // Flag to indicate if a sub-link is being edited
    };
  },

  model: {
    prop: "menu",
    event: "menu-changed",
  },

  created() {
    this.getProducts();
    this.getLobbies();
  },

  computed: {
    // Computed property to handle the selected product for the link
    productToLink: {
      get() {
        return this.productSelected;
      },
      set(value) {
        console.log(value);
        // If the value has a 'position' property, update the link position
        if (value.hasOwnProperty("position")) {
          this.link.position = value.position;
        } else {
          // Otherwise, update the selected product and link details
          this.productSelected = value;
          this.link.id = value.productId;
          this.link.title = value.name;
          this.link.href = `/product/${this.link.id}`;
        }
      },
    },
    // Computed property to handle the selected lobby for the link
    lobbyToLink: {
      get() {
        return this.lobbySelected;
      },
      set(value) {
        console.log(value);
        this.lobbySelected = value;
        // If the value has a 'position' property, update the link position
        if (value.hasOwnProperty("position")) {
          this.link.position = value.position;
        } else {
          // Otherwise, update the selected lobby and link details
          this.link.id = value._id;
          this.link.title = value.name;
          this.link.href = `/lobby?id=${value._id}`;
          this.link.position = this.productToLink.position || 0;
        }
      },
    },
    // Computed property to get the currently edited link (either a new link or the one being edited)
    correctLink: {
      get() {

      },
      set(newval) {
        if (this.editLink) this.updateLink(newval);
        else this.link = newval;
      },
    },
  },

  methods: {
    // Fetch available products from the server
    async getProducts() {
      //products get
      try {
        const clientId = store.state.whitelabelCurrencyNabvar.whitelabel.client;
        const response = await axios.get(
          `${this.backURL}/clients/availProd/${clientId}`
        );
        const objects = response.data;
        objects.forEach((element) => {
          this.products.push({
            productId: element.product.productId,
            name: element.product.name,
          });
        });
        // this.products = data.filter((pro) => pro.isgame);
      } catch (err) {
        console.error(err);
      }
    },

    // Fetch available lobbies from the server
    async getLobbies() {
      //lobbies get
      const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
      try {
        const response = await axios.get(this.backURL + "/lobbies",{
          params: {
            whitelabelId,
            templateId: editTemplate.value._id,
          }
        });
        const data = response.data.lobbys;
        this.lobbies = data;
      } catch (err) {
        console.error(err);
      }
    },

    // Handle the submission of the link form
    linkButtonHandler() {
      if (this.editLink) {
        this.$emit("link_edited");
      } else {
        this.addLink();
      }
    },

    // Handle sub-menu actions (add or update sub-link)
    handleSubMenuAction(index) {
      //si lo que se esta edtiando es un submenu
      //verifixar que no se este editando un hijo
      if (index[1] < 0) {
        this.addSubLink(index[0]);
      } else {
        this.updateSubLink(this.subLink);
      }
    },

    // Add a new sub-link to the specified link index
    addSubLink(index) {
      console.log("indice", index);
      if (this.menu.links[index].children) {
        this.menu.links[index].children.push(this.subLink);
      } else {
        this.menu.links[index].children = [this.subLink];
      }
      this.subLink = { buttonType: "link" };
      this.$emit("menu-changed", this.menu);
    },

    // Add a new link to the menu
    addLink() {
      this.menu.links.push(this.link);
      this.link = {};
      this.$emit("menu-changed", this.menu);
    },

    // Delete the currently edited link
    deleteLink() {
      let index = this.editLink.index;
      if (index.length == 1 || index[1] < 0) {
        this.menu.links.splice([index[0]], 1);
      } else if (index.length > 1) {
        this.menu.links[index[0]].children.splice(
          [index[1]],
          1
        );
      }
      this.productSelected = {};
      this.lobbySelected = {};

      this.$emit("menu-changed", this.menu);
      this.$emit("link_deleted");
    },

    // Update the currently edited link
    updateLink(val) {
      let index = this.editLink.index[0];
      this.$emit("menu-changed", this.menu);
    },

    // Update the currently edited sub-link
    updateSubLink(val) {
      let index = this.editLink.index;
      this.editingSubLink = false;
      this.$emit("menu-changed", this.menu);
    },

    // Set or reload the background image for the menu
    setOrRealoadImages(value) {
      if (value === "reload") return this.$emit("reload");
      this.menu.background_image = value;
    },
  },

  mounted() {},
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-row>
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input
                    id="name"
                    type="text"
                    v-model="menu.name"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
              #default="validationContext"
              name="fontSize"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="With" label-for="fontSize">
                  <template #label>{{ $t("font_size") }}</template>
                  <b-form-input
                    id="fontSize"
                    type="number"
                    v-model="menu.font_size"
                    :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
              #default="validationContext"
              name="BackgroundColor"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group
                  label="Background color"
                  label-for="backgroundcolor"
                >
                  <template #label>{{ $t("labels.backgroundcolor") }}</template>
                  <b-form-input
                    id="backgroundcolor"
                    type="color"
                    v-model="menu.background_color"
                    :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
              #default="validationContext"
              name="textColor"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="text color" label-for="textColor">
                  <template #label>{{ $t("text_color") }}</template>
                  <b-form-input
                    id="textColor"
                    type="color"
                    v-model="menu.color"
                    :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
              #default="validationContext"
              name="hoverColor"
              rules="required"
              class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="text color" label-for="hoverColor">
                  <template #label>{{ $t("labels.hovercolor") }}</template>
                  <b-form-input
                    id="hoverColor"
                    type="color"
                    v-model="menu.hover_color"
                    :state="getValidationState(validationContext)"
                  />

                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <image-field-form
                :preview="true"
                id="backgroundImage"
                :text="$t('labels.backgroundimage')"
                v-model="menu.background_image"
                @changeImage="setOrRealoadImages"
                path="menu"
                :images="images"
              >
              </image-field-form>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Settings">
          <div class="accordion" role="tablist">
            <b-list-group class="list-group-flush mt-2">
              <b-list-group-item style="background: none" class="px-0">
                <h6 v-b-toggle.accordion-1>{{ $t("new_button_data") }}</h6>
                <b-collapse
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <div class="px-1 mt-1">
                    <div class="d-flex align-items-center">
                      <b-col v-if="editLink" cols="12">
                        <b-button
                          @click="deleteLink"
                          variant="danger"
                          class="w-100 py-1"
                          >{{
                            `${$t("buttons.delete")}` + correctLink.buttonType
                          }}</b-button
                        >
                      </b-col>
                    </div>

                    <div class="d-flex align-items-center">
                      <b-col v-if="!editLink" class="border-md-left" cols="12">
                        <validation-provider
                          #default="validationContext"
                          name="type"
                        >
                          <b-form-group label-for="type">
                            <template #label>{{ $t("button_type") }}</template>
                            <v-select
                              id="title"
                              v-model="link.buttonType"
                              label="text"
                              :options="['link', 'submenu', 'product', 'lobby']"
                              :state="getValidationState(validationContext)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                      <template
                        v-if="
                          correctLink.buttonType === 'link' ||
                          (correctLink.buttonType === 'product' &&
                            !!editLink) ||
                          (correctLink.buttonType === 'lobby' && !!editLink)
                        "
                      >
                        <b-col class="border-md-left" cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="title"
                          >
                            <b-form-group label-for="title">
                              <template #label>{{ $t("title") }}</template>
                              <b-form-input
                                id="title"
                                type="text"
                                v-model="correctLink.title"
                                :state="getValidationState(validationContext)"
                              />

                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col class="border-md-left" cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="url"
                          >
                            <b-form-group label-for="url">
                              <template #label>url</template>
                              <b-form-input
                                :disabled="
                                  correctLink.buttonType === 'product' ||
                                  correctLink.buttonType === 'lobby'
                                "
                                id="url"
                                type="text"
                                v-model="correctLink.href"
                                :state="getValidationState(validationContext)"
                              />

                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="icon"
                          >
                            <b-form-group label-for="icon">
                              <template #label>icon</template>
                              <b-form-input
                                id="icon"
                                type="text"
                                v-model="correctLink.icon"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="position"
                          >
                            <b-form-group label-for="position">
                              <template #label>position</template>
                              <b-form-input
                                id="position"
                                type="number"
                                min="0"
                                v-model="correctLink.position"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </template>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                      <template v-if="correctLink.buttonType === 'submenu'">
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="title"
                          >
                            <b-form-group label-for="title">
                              <template #label>{{ $t("title") }}</template>
                              <b-form-input
                                id="title"
                                type="text"
                                v-model="correctLink.title"
                                :state="getValidationState(validationContext)"
                              />

                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="icon"
                          >
                            <b-form-group label-for="icon">
                              <template #label>{{ $t("icon") }}</template>
                              <b-form-input
                                id="icon"
                                type="text"
                                v-model="correctLink.icon"
                                :state="getValidationState(validationContext)"
                              />

                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="position"
                          >
                            <b-form-group label-for="position">
                              <template #label>{{ $t("position") }}</template>
                              <b-form-input
                                id="position"
                                type="number"
                                min="0"
                                v-model="correctLink.position"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </template>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                      <template
                        v-if="correctLink.buttonType === 'product' && !editLink"
                      >
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="Products"
                            rules="required"
                          >
                            <b-form-group label-for="products">
                              <template #label
                                >{{ $t("labels.products") }}
                              </template>
                              <v-select
                                v-model="productToLink"
                                label="name"
                                :options="products"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="position"
                          >
                            <b-form-group label-for="position">
                              <template #label>position</template>
                              <b-form-input
                                id="position"
                                type="number"
                                min="0"
                                v-model="productToLink.position"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </template>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                      <template
                        v-if="correctLink.buttonType === 'lobby' && !editLink"
                      >
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="lobby"
                            rules="required"
                          >
                            <b-form-group label-for="lobby">
                              <template #label>{{ $t("lobby") }} </template>
                              <v-select
                                v-model="lobbyToLink"
                                label="name"
                                track-by="name"
                                :options="lobbies"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12">
                          <validation-provider
                            #default="validationContext"
                            name="position"
                          >
                            <b-form-group label-for="position">
                              <template #label>position</template>
                              <b-form-input
                                id="position"
                                type="number"
                                min="0"
                                v-model="lobbyToLink.position"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </template>
                    </div>

                    <div class="d-flex align-items-center">
                      <b-col
                        v-if="correctLink.buttonType"
                        class="border-md-left"
                        cols="12"
                      >
                        <b-button
                          @click="linkButtonHandler"
                          variant="primary"
                          class="mr-1"
                          >{{
                            editLink
                              ? $t("tabs.edit") + correctLink.buttonType
                              : $t("tabs.add") + correctLink.buttonType
                          }}</b-button
                        >
                        <b-button
                          v-if="editLink"
                          variant="secondary"
                          @click="$emit('edit_canceled')"
                        >
                          {{ $t("buttons.cancel") }}
                        </b-button>
                      </b-col>
                    </div>
                  </div>
                </b-collapse>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button size="md" type="submit" class="w-100" variant="success">
            {{ isEditMode ?  $t("buttons.update_and_apply") : $t('buttons.create_and_apply') }}
          </b-button>
          <b-button @click="resetData()"> Reset </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss"></style>
