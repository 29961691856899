<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("labels.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <div>
                <b-button
                  variant="primary"
                  :to="{
                    name: 'apps-footers-store-app-template',
                    query: { templateId: templateID },
                  }"
                >
                  <span class="text-nowrap">{{ $t("buttons.add") }}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay
        :show="loading"
        :variant="$store.state.appConfig.layout.skin"
        blur="2"
        class="p-50"
      >
        <b-table
          ref="refFooterListTable"
          class="position-relative"
          :items="fetchFooters"
          responsive
          :fields="tableColumns"
          primary-key="_id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('list_empty')"
          :sort-desc.sync="isSortDirDesc"
          style="min-height: 60vh"
        >
          <template #cell(logo)="data">
            <image-table-field
              v-if="data.item.logo && data.item.logo.href"
              :url="data.item.logo.href"
            ></image-table-field>
          </template>
          <template #cell(background)="data">
            <color-table-field :color="data.item.background_color">
              <image-table-field
                :url="data.item.background_image"
              ></image-table-field>
            </color-table-field>
          </template>
          <!-- Column: Actions -->
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="d-flex">
              <b-button
                variant="primary"
                class="btn-icon rounded-circle mr-1"
                @click="selectFooter(data.item._id)"
                :style="
                  isSelected(data.item._id) ? 'pointer-events: none;' : ''
                "
                v-b-tooltip.hover.top="$t('labels.select')"
                size="sm"
              >
                <feather-icon
                  :icon="
                    isSelected(data.item._id) ? 'CheckCircleIcon' : 'CircleIcon'
                  "
                />
              </b-button>

              <b-button
                v-if="isSelected(data.item._id)"
                variant="primary"
                class="btn-icon rounded-circle mr-1"
                v-b-tooltip.hover.top="$t('tooltips.edit')"
                size="sm"
                :to="{
                  name: 'apps-footers-store-app-template',
                  params: { id: data.item._id },
                  query: { templateId: templateID },
                }"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="primary"
                class="btn-icon rounded-circle mr-1"
                v-if="!isSelected(data.item._id)"
                v-b-tooltip.hover.top="$t('tooltips.delete')"
                @click="removeFooter(data.item._id)"
                size="sm"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("labels.showing") }} {{ dataMeta.from }}
              {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
              {{ dataMeta.of }} {{ $t("labels.entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFooters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, computed, onUnmounted } from "@vue/composition-api";
import useFootersList from "./useFooterList";
import footerStoreModule from "./store/footerStoreModule";
import ColorTableField from "@/views/components/ColorTableField.vue";
import ImageTableField from "@/views/components/ImageTableField.vue";
import {
  showSuccessToast,
  showErrorToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import i18n from "@/libs/i18n";
import { useToast } from "vue-toastification/composition";

export default {
  name: "FooterList",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    ImageTableField,
    ColorTableField,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup(props, { emit }) {
    const FOOTER_APP_STORE_MODULE_NAME = "app-footer";

    const toast = useToast();

    if (!store.hasModule(FOOTER_APP_STORE_MODULE_NAME))
      store.registerModule(FOOTER_APP_STORE_MODULE_NAME, footerStoreModule);

    const isAddNewFooterSidebarActive = ref(false);

    const prepareUpdateOrCreate = (item = null, action = "edit") => {
      emit("updateOrCreate", { item, action });
    };

    const {
      fetchFooters,
      perPage,
      currentPage,
      totalFooters,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFooterListTable,
      refetchData,
      loading,
      recordDeleted,
      selectFooter,
      isSelected,
      templateID,
      resolveFooterStatusVariant,
    } = useFootersList();

    const tableColumns = computed(() => [
      { key: "name", sortable: false, label: i18n.t("labels.firstname") },
      {
        key: "description",
        sortable: false,
        label: i18n.t("labels.description"),
      },
      { key: "actions", label: i18n.t("labels.actions") },
    ]);

    const removeFooter = async (Id) => {
      try {
        await store.dispatch("app-footer/removeFooter", { id: Id });
        recordDeleted.value = !recordDeleted.value;
        showSuccessToast(toast, "Footer", "Footer removed");
      } catch (error) {
        showErrorToast(toast, "Error", axiosErrorHandle(error));
      }
    };

    return {
      isAddNewFooterSidebarActive,
      fetchFooters,
      perPage,
      currentPage,
      totalFooters,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFooterListTable,
      refetchData,
      recordDeleted,
      selectFooter,
      isSelected,
      loading,
      prepareUpdateOrCreate,
      templateID,
      resolveFooterStatusVariant,
      tableColumns,
      removeFooter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select";
</style>
