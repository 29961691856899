import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import { computed, ref, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";

export default function useMenuList() {
  const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

  // Register module
  if (!store.hasModule(MODULE_APP_STORE_NAME))
    store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);

  const toast = useToast();
  const refMenuListTable = ref(null);
  const loading = ref(false);
  const perPage = ref(10);
  const totalMenus = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(false);
  const recordDeleted = ref(false);

  const editTemplate = computed(
    () => store.state["app-whitelabel-templates"].editTemplate
  );

  const dataMeta = computed(() => {
    const localItemsCount = refMenuListTable.value?.localItems.length || 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMenus.value,
    };
  });

  const refetchData = () => {
    refMenuListTable.value?.refresh();
  };

  const isSelected = (id) => editTemplate.value.templateData?.menu._id === id;

  const findSelected = (id) => {
    return (
      refMenuListTable.value?.localItems.find((item) => item._id === id) || null
    );
  };

  const selectMenu = async (id) => {
    const selected = findSelected(id);

    if (!selected) return;

    const updatedTemplate = {
      ...editTemplate.value,
      templateData: {
        ...editTemplate.value.templateData,
        menu: selected
      }
    };

    store.commit('app-whitelabel-templates/SET_EDIT_TEMPLATE', updatedTemplate);

    try {
      await store.dispatch("app-whitelabel-templates/updateTemplate", {
        template: updatedTemplate,
        toast,
        path: "templateData.menu"
      });
      showSuccessToast(toast, "Success", "Menu updated successfully");
    } catch (error) {
      showErrorToast(toast, "Error updating menu", axiosErrorHandle(error));
    }
  };

  const sortLinks = (links) => {
    if (links.length > 0) {
      return links.sort((a, b) => Number(a.position) - Number(b.position));
    }
    return links;
  };

  const fetchMenus = (ctx, callback) => {
    loading.value = true;

    store
      .dispatch("app-menu/fetchMenus", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
        templateId: editTemplate.value._id,
      })
      .then((response) => {
        loading.value = false;
        const { total, menus } = response.data;
        menus.map((item) => {
          return (item.links = sortLinks(item.links));
        });
        callback(menus);
        totalMenus.value = total;
      })
      .catch((error) => {
        loading.value = false;
        showErrorToast(
          toast,
          "Error fetching menus list",
          axiosErrorHandle(error)
        );
      });
  };

  watch([currentPage, perPage, searchQuery, recordDeleted], () => {
    refetchData();
  });

  return {
    isSelected,
    fetchMenus,
    perPage,
    currentPage,
    totalMenus,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMenuListTable,
    refetchData,
    recordDeleted,
    loading,
    selectMenu,
    editTemplate,
  };
}
